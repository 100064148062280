import image1 from "./assets/kirill_alex.png";
import image2 from "./assets/uliana_serg.png";
import image3 from "./assets/karen_vlad.png";
import image4 from "./assets/alex_alex.png";
import image5 from "./assets/angelic_akop.png";
import image6 from "./assets/anastasia_alex.jpeg";
import image7 from "./assets/karina_yakov.png";
import image8 from "./assets/alina_gena.jpg";
import image9 from "./assets/danil_sergeevich.png";
import image10 from "./assets/dmitriy_ura.png";
import image11 from "./assets/elmar_valera.png";
import image12 from "./assets/dmitriy_vadim.png";

export const teamData = [
  {
    id: 1,
    name: "Кирилл Вячеславович",
    dignity: "Кандидат в мастера спорта",
    description:
      "Чемпион города Ростов-на-Дону. Чемпион Ростовской области. Призер Всероссийских лично-командных турниров по быстрым шахматам. Многократный призер и победитель чемпионатов Ростовской области среди студентов",
    image: image1,
  },
  {
    id: 2,
    name: "Ульяна Сергеевна",
    dignity: "Кандидат в мастера спорта",
    description:
      "Многократный призер и чемпионка Северо-Кавказского Федерального округа по классическим, быстрым шахматам и блицу. Дважды участница Финала Высшей лиги России среди женщин. Серебряный призер Спартакиады в Белгороде, чемпионка и призер областных межвузовских соревнований ",
    image: image2,
  },
  {
    id: 3,
    name: "Карен Владимирович",
    dignity: "Кандидат в мастера спорта",
    description:
      "Призер Ростовской области и Южного федерального округа по шахматам.",
    image: image3,
  },
  {
    id: 4,
    name: "Александр Александрович",
    dignity: "Мастер ФИДЕ",
    description: `Чемпион России в высшей лиге (2007); Многократный призер чемпионата России в высшей лиге среди юниоров; Призер чемпионата Европы среди юниоров (2005); Многократный чемпион ЮФО среди юниоров; двукратный победитель международного турнира “Archibald Chess”; 3 место в XXVII Międzynarodowy Festiwal Szachowy CRACOVIA'2016 (Польша, Краков); Трёхкратный чемпион Всероссийских студенческих соревнований "Kalmyk Chess" (2017-2019 г.)`,
    image: image4,
  },
  {
    id: 5,
    name: "Анжелика Акоповна",
    dignity: "Кандидат в мастера спорта",
    description:
      "Многократный призер и чемпионка ЮФО по классическим шахматам, быстрым шахматам. Победительница Всероссийского турнира по быстрым шахматам среди женщин в 2016г. Бронзовый призер открытого Всероссийского турнира в Сочи в 2016г. Участника и призер областных межвузовских соревнований.",
    image: image5,
  },
  {
    id: 6,
    name: "Анастасия Александровна",
    dignity: "Кандидат в мастера спорта",
    description:
      "7-кратная чемпионка Воронежской области. Призер международных шахматных соревнований. Призер МСИИ в составе сборной ВУЗа. 3-кратный победитель кубка Ректора",
    image: image6,
  },
  {
    id: 7,
    name: "Карина Яковлевна",
    dignity: "1 разряд",
    description:
      "Участница Всероссийских и Международных турниров. С легкостью находит общий язык с детьми.",
    image: image7,
  },
  {
    id: 8,
    name: "Алина Геннадьевна",
    dignity: "1 разряд",
    description:
      "Участница Всероссийских и Международных турниров. С легкостью находит общий язык с детьми.",
    image: image8,
  },
  {
    id: 9,
    name: "Данил Сергеевич",
    dignity: "Мастер ФИДЕ",
    description:
      "Участница Всероссийских и Международных турниров. С легкостью находит общий язык с детьми.",
    image: image9,
  },
  {
    id: 10,
    name: "Дмитрий Юрьевич",
    dignity: "Кандидат в мастера спорта",
    description:
      "Организовывал Международные турниры - Мемориал Владимира Дворковича: Традиционный летний турнир и Этапы Кубка России по классическим и быстрым шахматам. Судейство Международных и Всероссийских соревнований; этапы Кубков России, Чемпионаты России по классике, блицу и быстрым шахматам.",
    image: image10,
  },
  {
    id: 11,
    name: "Эльмар Валерьевич",
    dignity: "Кандидат технических наук, кандидат в мастера спорта",
    description:
      "Многократный чемпион города Ейск по шахматам; победитель студенческо-юношеского турнира по шахматам в рамках Этапа Кубка России; призер Всероссийских молодежных лично-командных турниров; Призер X Всероссийского фестиваля студенческого спорта; Тренер сборной команды Южного федерального университета",
    image: image11,
  },
  {
    id: 12,
    name: "Дмитрий Вадимович",
    dignity: "Международный Гроссмейстер",
    description: `Победитель опен-турниров в Дели и Нови-Саде (2010 год), Берлине (2017), Дитцине (2018). 
Чемпион Южного, Уральского и Северо-Кавказского федеральных округов по шахматам.
Гроссмейстер и журналист 
В 2015 году занял третье место в номинации "Лучший детский тренер России". Среди его учеников Андрей Есипенко, Даниил Юффа, Дмитрий Цой, Арсений Нестеров. Серебряный призер чемпионата России по блицу в составе команды "Малахит" (2016). Чемпион СКФО (2017). Победитель крупного рапид-турнира в Серпухове (2018)`,
    image: image12,
  },
];
