export const cards_1 = [
  {
    id: 1,
    title: "Профессиональные тренера",
    description: "Учим ставить цели и достигать\u00A0их",
  },
  {
    id: 2,
    title: "Эффективные упражнения",
    description: "Развиваем все\u00A0виды\u00A0памяти",
  },
  {
    id: 3,
    title: "Познавательный процесс",
    description:
      "Развиваем умение, навыки анализировать и планировать свои\u00A0действия",
  },
];

export const cards_2 = [
  {
    id: 4,
    title: "Оптимальный график",
    description: "Удобный график. Составьте расписание под\u00A0себя",
  },
  {
    id: 5,
    title: "Соревнования",
    description: "Еженедельные тематические турниры",
  },
  {
    id: 6,
    title: "Интересные занятия",
    description:
      "Проведения занятий в интересной, размереной форме, без\u00A0спешки",
  },
];
