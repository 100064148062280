export const navigatePanel = [
  {
    id: 1,
    title: "О нас",
    link: "about",
  },
  {
    id: 2,
    title: "Преимущества",
    link: "benefit",
  },
  {
    id: 3,
    title: "Команда",
    link: "team",
  },
  {
    id: 4,
    title: "Прайс",
    link: "price",
  },
  {
    id: 5,
    title: "Бонусы",
    link: "bonuses",
  },
];
